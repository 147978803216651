import React from 'react';
import { useEffect, useState } from 'react';
import { faCalendarPlus } from '@fortawesome/pro-regular-svg-icons/faCalendarPlus';
import { faPersonHiking } from '@fortawesome/pro-regular-svg-icons/faPersonHiking';
import { faTreePalm } from '@fortawesome/pro-regular-svg-icons/faTreePalm';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, styled, Theme, Tooltip, tooltipClasses, TooltipProps, useMediaQuery } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { apiMeUrl, fetchWithTimeout, getTimeAccountInfo, getVacationInfo } from '../helpers/dataFetcher';
import { ApiMe, TimeAccountData, VacationData } from '../helpers/dataTypeHelper';
import { hashQueryString } from '../helpers/hashHelper';
import { Panel } from './ui';
import './VacationPanel.less';

type Props = {
    randomNum: number;
    planVacation: boolean;
};

const VacationPanel = ({ randomNum, planVacation }: Props) => {
    const [vacationData, setVacationData] = useState<VacationData>({
        PlannedHalf: 0,
        RemainingHalf: 0,
        UsedHalf: 0,
        Planned: 0,
        Remaining: 0,
        Used: 0,
    });

    const [employeeNr, setEmployeeNr] = useState<Omit<ApiMe, 'Hovedavdeling'>>({
        Id: 0,
        FirmaId: 0,
        MalId: 0,
        Avdeling: '',
    });

    const [timeAccountData, setTimeAccountData] = useState<TimeAccountData>({
        ans: employeeNr.Id,
        fom: '',
        maksminustid: 0,
        maksplusstid: 0,
        overmaks: false,
        saldo: 0,
        til: '',
        // Hvilket tidskonto-system(tkttype) som skal brukes.
        // 0=Deaktivert.
        // 2=Metode 1 dag - mot forventet.
        // 2=Metode 2 dag - mot minustid.
        // 3=Metode 3 uke - mot turnus.
        tktype: 0,
        undermin: false,
        message: '',
    });

    const hours = (Math.round(timeAccountData.saldo * 100) / 100).toFixed(2);
    const maxPlusHours = timeAccountData.maksplusstid;
    const maxMinusHours = timeAccountData.maksminustid;
    const [message, setMessage] = useState<boolean>(true);
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1 >= 10 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`;
    const day = today.getDate() >= 10 ? today.getDate() : `0${today.getDate()}`;

    const p = usePhrases('hjem');
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));

    const fetchVacationInfo = (hashedRes: string) => {
        try {
            getVacationInfo(hashedRes).then((data: VacationData) => {
                setVacationData(data);
            });
        } catch (e) {
            console.error('Error', e);
        }
    };

    const fetchData = async () => {
        try {
            fetchWithTimeout(apiMeUrl)
                .then((data: ApiMe) => {
                    setEmployeeNr(data);
                    const hashedRes = hashQueryString(true, randomNum, data);
                    fetchVacationInfo(hashedRes);
                })
                .catch((e: Error) => {
                    console.error(e);
                    const hashedRes = hashQueryString(true, randomNum, undefined);
                    fetchVacationInfo(hashedRes);
                });
            getTimeAccountInfo().then((data: TimeAccountData) => {
                if (data.message) {
                    setMessage(false);
                }
                setTimeAccountData(data);
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    let timeAccountInfo;

    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });

    if (message) {
        timeAccountInfo = (
            <div className="TimeAccount">
                <FontAwesomeIcon className="VacationIcon" icon={faPlus} />
                <p className="Number">
                    {spanWrap(p('bank_hours', `*${hours}*`))}
                    <a className="TimeAccountLink" href={`rptTimeanalyse_kontodetaljer.asp?ans=${employeeNr.Id}`}>
                        {' '}
                        {p('bank_name')}
                    </a>
                </p>
            </div>
        );
    } else {
        timeAccountInfo = (
            <div className="TimeAccount">
                <FontAwesomeIcon className="VacationIcon" icon={faPlus} />
                <CustomWidthTooltip title={<Box sx={{ fontSize: 14 }}>{p('bank_err')}</Box>} placement="top" arrow>
                    <p data-tip data-for="TimeAccountMessage" className="Number">
                        {spanWrap(p('bank_hours', `*0*`))}
                        <a className="TimeAccountLink" href={`rptTimeanalyse_kontodetaljer.asp?ans=${employeeNr.Id}`}>
                            {' '}
                            {p('bank_name')}
                        </a>
                    </p>
                </CustomWidthTooltip>
            </div>
        );
    }

    const generateAnchorUrl = () => {
        const baseUrl =
            window.location.href.includes('lokal.tidsbanken') || window.location.href.includes('sandkasse.tidsbanken')
                ? 'https://sandkasse.tidsbanken.net'
                : 'https://min.tidsbanken.net';
        return `${baseUrl}/rapport-feriedager.asp?ansatt=${employeeNr.Id}&fradato=${year}-01-01&tildato=${year}-12-31&gruppert=false&dato= `;
    };

    return (
        <Panel customClass="VacationPanel">
            <div className="VacationDays">
                <FontAwesomeIcon className="VacationIcon" icon={faTreePalm} />
                <p className="Number Margin">
                    {spanWrapAnsattPanel(
                        p(
                            'vacay_days',
                            `*${vacationData.Used}*`,
                            `*${vacationData.Remaining}*`,
                            `*${vacationData.Planned}*`
                        ),
                        employeeNr.Id,
                        [2]
                    )}
                </p>
            </div>

            {planVacation ? (
                <div className="VacationDays">
                    <FontAwesomeIcon className="VacationIcon" icon={faCalendarPlus} />
                    <p className={'Number'}>
                        {p('vacay_add')}
                        <strong>
                            <a
                                className="vacation-request-link"
                                href={
                                    window.location.href.includes('lokal.tidsbanken') ||
                                    window.location.href.includes('sandkasse.tidsbanken')
                                        ? `https://sandkasse.tidsbanken.net/plan?visning=${
                                              isMobile ? 'uke' : 'maaned'
                                          }&dato=${year}-${month}-${day}&ferie=true&ansatt=${employeeNr.Id}&avdeling=${
                                              employeeNr.Avdeling
                                          }`
                                        : `https://min.tidsbanken.net/plan?visning=${
                                              isMobile ? 'uke' : 'maaned'
                                          }&dato=${year}-${month}-${day}&ferie=true&ansatt=${employeeNr.Id}&avdeling=${
                                              employeeNr.Avdeling
                                          }`
                                }
                            >
                                {' '}
                                {p('vacay_vacation_request')}
                            </a>
                        </strong>
                    </p>
                </div>
            ) : (
                ''
            )}

            {vacationData.RemainingHalf + vacationData.UsedHalf + vacationData.PlannedHalf > 0 ? (
                <div className="VacationDays HalfVacationDays">
                    <FontAwesomeIcon className="VacationIcon" icon={faPersonHiking} />
                    <p className="Number">
                        {spanWrap(
                            p(
                                'vacay_days_half',
                                `*${vacationData.UsedHalf}*`,
                                `*${vacationData.RemainingHalf}*`,
                                `*${vacationData.PlannedHalf}*`
                            )
                        )}
                    </p>
                </div>
            ) : (
                <></>
            )}

            {timeAccountData.tktype === 0 ? '' : timeAccountInfo}

            {timeAccountData.overmaks || timeAccountData.undermin ? (
                <div className="TimeAccountAlert">
                    <FontAwesomeIcon className="AlertIcon" icon={faBell} size={'2x'} />
                    {timeAccountData.overmaks ? (
                        <p className="AlertText ExtraHours">{p('bank_warn_max', hours, `${maxPlusHours}`)}</p>
                    ) : (
                        ''
                    )}
                    {timeAccountData.undermin ? (
                        <p className="AlertText MinusHours">{p('bank_warn_min', hours, `${maxMinusHours}`)}</p>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                ''
            )}
        </Panel>
    );
};

const spanWrap = (str: string, anchorIndices?: number[], url?: string): JSX.Element => {
    const elArr: JSX.Element[] = [];
    const arr = str.split('*');

    for (let i = 0; i < arr.length; ++i) {
        if (anchorIndices && anchorIndices.includes(i)) {
            elArr.push(
                <strong key={i}>
                    {' '}
                    <a href={url}>{arr[i]}</a>
                </strong>
            );
        } else if (i % 2 === 1) {
            elArr.push(<span key={i}>{arr[i]}</span>);
        } else {
            elArr.push(<React.Fragment key={i}>{arr[i]}</React.Fragment>);
        }
    }

    return <>{elArr}</>;
};

const spanWrapAnsattPanel = (str: string, employeeId: number, anchorIndices?: number[]): JSX.Element => {
    const elArr: JSX.Element[] = [];
    const arr = str.split('*');

    for (let i = 0; i < arr.length; ++i) {
        if (anchorIndices && anchorIndices.includes(i)) {
            elArr.push(
                <strong key={i}>
                    {' '}
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.employeePanel.open(employeeId, { openPanels: ['ferie'] })}
                    >
                        {arr[i]}
                    </a>
                </strong>
            );
        } else if (i % 2 === 1) {
            elArr.push(<span key={i}>{arr[i]}</span>);
        } else {
            elArr.push(<React.Fragment key={i}>{arr[i]}</React.Fragment>);
        }
    }

    return <>{elArr}</>;
};

export default VacationPanel;
